import React, { useCallback } from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
// Formik
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
// mui
import Grid from '@mui/material/Grid';
// datepicker
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from 'formik-mui-x-date-pickers';

const OobGrid = ({
  handleBlur,
  setFieldTouched,
  setFieldValue,
  values,
  handleChange,
}) => {
  const onChange = useCallback(
    (e) => {
      if (e.target.checked === true) {
        handleBlur(e); // force blur to update validation
        setFieldTouched('oobDate');
      } else {
        setFieldValue('oobDate', null);
      }

      handleChange(e);
    },
    [handleBlur, setFieldTouched, setFieldValue, handleChange],
  );

  const onDateChange = useCallback(
    (e) => {
      const value = new Date(e.$d);
      setFieldValue('oobDate', value.toLocaleDateString());
      // handleChange(e);
    },
    [setFieldValue],
  );

  if (!values.updateOob) return null;
  return (
    <Grid container spacing={2} p={2} alignItems="center">
      <Grid
        item
        xs={6}
        md={6}
        style={{ paddingLeft: '16px', paddingTop: '16px' }}
      >
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          Label={{ label: 'Out of Business' }}
          name="isOob"
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Field
            component={DatePicker}
            name="oobDate"
            label="Out of Business Date"
            inputFormat="MM/DD/YYYY"
            disabled={!values || !values.isOob}
            textField={{
              variant: 'standard',
              disabled: !values || !values.isOob,
            }}
            onChange={onDateChange}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

OobGrid.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};
export default OobGrid;
