import React from 'react'; // skipcq: JS-0128
// icons
import { TiBeer } from 'react-icons/ti';
import { MdRestaurantMenu } from 'react-icons/md';
// import { GiFoodTruck } from 'react-icons/gi';
// import FoodTruck from '../icons/FoodTruck';
//
// import { secondaryColor } from '../../utils/constants';

export const getAmenityTypes = () => {
  const iconStyle = {
    paddingRight: '4px',
  };

  const amenityTypes = new Map();
  // TODO - move to regular project, load from json
  amenityTypes.set('hasTapRoom', {
    priority: 1,
    icon: <TiBeer style={iconStyle} />,
    label: 'Tap room',
    antiLabel: 'No tap room',
    displayAnti: true,
  });
  amenityTypes.set('hasFoodMenu', {
    priority: 2,
    icon: <MdRestaurantMenu style={iconStyle} />,
    label: 'Food menu',
    antiLabel: 'No food menu',
    displayAnti: true,
  });
  // amenityTypes.set('hasFoodTruck', {
  //   priority: 3,
  //   icon: <FoodTruck style={iconStyle} />,
  //   label: 'Food truck',
  //   displayAnti: false,
  // });
  return amenityTypes;
};
