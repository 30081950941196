import React from 'react';
import PropTypes from 'prop-types';
//
import Social from './Social';
Socials.propTypes = {
  socials: PropTypes.array,
};

function Socials({ socials }) {
  if (!socials || socials.length < 1) {
    return null;
  }
  return (
    <div>
      {socials.map((social) => {
        return <Social social={social} key={social.link} />;
      })}
    </div>
  );
}

export default Socials;
