import React from 'react';
import PropTypes from 'prop-types';
// libs
import dayjs from 'dayjs';
// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/joy/Typography';
import Link from '@mui/material/Link';
// Icons
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
// components
import Socials from '../../components/socials/Socials';
// utils
import sanitizedUrl from '../../utils/sanitizeURL';

function BreweryDeets({
  name,
  address1,
  city,
  state,
  zip,
  phone,
  website,
  isOob,
  oobDate,
  email,
  socials,
}) {
  let formattedOobDate = '';
  if (oobDate) {
    formattedOobDate = dayjs(oobDate).format('MM/DD/YYYY');
  }

  // take the irl and make it a link
  const url = sanitizedUrl(website);

  return (
    <Grid container style={{ display: 'flex' }} flex p={2} spacing={2}>
      {isOob && (
        <Grid item xs={12}>
          <Typography textColor="red">
            Out of business {formattedOobDate}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography level="h4">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{address1}</Typography>
        <Typography>{city}</Typography>
        <Typography>{state}</Typography>
        <Typography>{zip}</Typography>
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex' }}>
        {phone !== '' && (
          <>
            <Phone />
            {phone}
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} style={{ display: 'flex' }}>
        {email !== '' && (
          <>
            <Email />
            {email}
          </>
        )}
      </Grid>
      <Grid item xs={12} style={{ display: 'flex' }}>
        {website !== '' && <Link href={url}>{url} </Link>}
      </Grid>
      <Grid item xs={11} md={5}>
        <Socials socials={socials} />
      </Grid>
    </Grid>
  );
}

BreweryDeets.propTypes = {
  name: PropTypes.string,
  address1: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  phone: PropTypes.string,
  website: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
  socials: PropTypes.array,
};

BreweryDeets.defaultProps = {
  name: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  website: '',
  facebook: '',
  twitter: '',
  socials: [],
};

export default BreweryDeets;
