function sanitizedUrl(url) {
  if (!url) return '';

  let cleanUrl = url;

  // if the last character is a / then trimmit
  if (cleanUrl.charAt(cleanUrl.length - 1) === '/') {
    cleanUrl = cleanUrl.substr(0, cleanUrl.length - 1);
  }

  if (cleanUrl.substring(0, 12) === 'https://www.') {
    return cleanUrl;
  }
  if (cleanUrl.substring(0, 11) === 'http://www.') {
    return cleanUrl;
  }

  if (cleanUrl.substring(0, 4) === 'www.') {
    cleanUrl = `https://${cleanUrl}`;
    return cleanUrl;
  }
  cleanUrl = `https://www.${cleanUrl}`;

  return cleanUrl;
}

export default sanitizedUrl;
