import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
// libs
import { Formik, Form } from 'formik';
import { string, boolean, date, array, object } from 'yup';
import 'yup-phone';
// Material UI
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// components
import ButtonHeader from '../../../../components/widgets/ButtonHeader';
import DetailsForm from '../overrideBreweryForm';
import SocialsForm from '../socialsForm/SocialsForm';
import AmenitiesForm from '../amenitiesForm/AmenitiesForm';
import FormDivider from '../../../../components/widgets/FormDivider';

function DetailsFormHandler({ brewery, override, handleUpdate }) {
  if (!brewery || !override) return null;

  const {
    name = '',
    address1 = '',
    city = '',
    state = '',
    zip = '',
    phone = '',
    isOob = false,
    oobDate = null,
    website = '',
    email = '',
    socials = [],
    logoUrl = '',
    updateAmenities = false,
    updateSocials = false,
    updateOob = false,
    amenities = {},
  } = override;

  const initialValues = {
    name,
    address1,
    city,
    state,
    zip,
    phone,
    isOob: isOob ? isOob : brewery.isOob,
    oobDate: isOob ? oobDate : brewery.oobDate,
    website,
    email,
    socials: socials && socials.length > 0 ? socials : brewery.socials,
    logoUrl,
    amenities:
      amenities && amenities.length > 0 ? amenities : brewery.amenities,
    updateAmenities,
    updateSocials,
    updateOob,
  };

  const brewerySchema = object().shape(
    {
      name: string(),
      address1: string(),
      city: string(),
      state: string(),
      zip: string(),
      phone: string().when('phone', {
        is: (value) => value?.length > 0,
        then: string().phone(),
        otherwise: string(),
      }),
      //phone: string().phone(),
      website: string(),
      updateOob: boolean(),
      isOob: boolean(),
      oobDate: date().when('isOob', {
        is: (isOob) => isOob === true, // skipcq: JS-0123
        then: date()
          .required('A valid date is required')
          .typeError('A valid date is required'),
        otherwise: date().nullable(),
      }),
      email: string().email(),
      updateAmnenities: boolean(),
      updateSocials: boolean(),
      socials: array().when('updateSocials', {
        is: (updateSocials) => updateSocials === true, // skipcq: JS-0123
        then: array().of(
          object().shape({
            type: string().oneOf([
              'facebook',
              'twitter',
              'instagram',
              'google',
              'yelp',
              'tripadvisor',
              'other',
            ]),
            link: string().url().required(),
          }),
        ),
        otherwise: array().nullable(),
      }),
    },
    ['phone', 'phone', 'isOob', 'oobDate', 'updateOob', 'updateSocials'],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={brewerySchema}
      onSubmit={handleUpdate}
    >
      {({
        handleBlur,
        handleChange,
        isSubmitting,
        isValid,
        submitForm,
        values,
        setFieldTouched,
        setFieldValue,
      }) => (
        <>
          <ButtonHeader>
            <Grid
              container
              spacing={2}
              item
              xs={12}
              justifyContent={'space-between'}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting || !isValid}
                onClick={submitForm}
                style={{ margin: '5px' }}
              >
                Save
              </Button>
            </Grid>
          </ButtonHeader>
          <FormDivider title="Brewery Updates" />
          <Container
            fixed
            style={{ justifyContent: 'center' }}
            sx={{
              display: 'flex',
            }}
          >
            <Form>
              <DetailsForm
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                values={values}
              />
              <SocialsForm values={values} handleChange={handleChange} />
              <AmenitiesForm
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
              />
            </Form>
          </Container>
        </>
      )}
    </Formik>
  );
}

DetailsFormHandler.propTypes = {
  handleBackNavigaton: PropTypes.func.isRequired,
  backNavigationName: PropTypes.string,
  brewery: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default DetailsFormHandler;
