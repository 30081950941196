import React from 'react';
import PropTypes from 'prop-types';
// mui
import Grid from '@mui/material/Grid';
// components
import Amenities from '../../components/amenities/Amenities';

function BreweryAmenities({ amenities }) {
  return (
    <Grid container padding={2} spacing={2}>
      <Grid item xs={12} md={6}>
        <Amenities
          amenities={amenities}
          settings={{ showLabels: true, vertical: true }}
        />
      </Grid>
    </Grid>
  );
}

BreweryAmenities.propTypes = {
  hasTaproom: PropTypes.bool,
};

export default BreweryAmenities;
