import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
//
import { UserContext } from '../context/UserContext';

const GlobalAdminRoute = ({ children }) => {
  const user = useContext(UserContext);
  if (!user.isGlobalAdmin) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export default GlobalAdminRoute;
