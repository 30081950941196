import React from 'react';
import PropTypes from 'prop-types';
//components
import Amenity from './Amenity';
import { getAmenityTypes } from './AmenityTypes';

Amenities.propTypes = {
  amenities: PropTypes.object.isRequired,
};

function Amenities({ amenities, settings }) {
  const amenityTypes = getAmenityTypes();
  // const amenitiesList = Object.entries(amenities);
  // console.log(amenities);
  // console.log(amenitiesList);

  const amenitiesList = Array.from(amenityTypes.entries()).map(
    (amenityType, index) => {
      const amenityValue = amenities[amenityType[0]];
      // console.log(amenityType);
      return (
        <Amenity
          key={index}
          amenityValue={amenityValue}
          amenityType={amenityType}
          settings={settings}
        />
      );
    },
  );

  // console.log(amenitiesList);

  // amenitiesList.map((amenity) => {
  //   const amenityType = amenityTypes.get(amenity[0]);
  //   if (!amenityType) {
  //     // we don;t know the amenity Type, get out of here
  //     return null;
  //   }
  //   return (
  //     <Amenity
  //       amenity={amenity}
  //       amenityType={amenityType}
  //       settings={settings}
  //     />
  //   );
  // });

  return <div>{amenitiesList}</div>;
}

export default Amenities;
