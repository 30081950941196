import React from 'react'; // skipcq: JS-0128
// formik
import { Field, FieldArray } from 'formik';
import { TextField, Select, Switch } from 'formik-mui';
// mui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

function SocialsGrid({ values }) {
  if (!values.updateSocials) return null;

  return (
    <FieldArray
      name="socials"
      // skipcq: JS-0417
      render={(arrayHelpers) => (
        <>
          {values.socials?.map((social, index) => (
            // skipcq: JS-0437
            <Grid container spacing={2} p={2} key={index}>
              <Grid item xs={4}>
                <Field
                  component={Select}
                  id="type"
                  type="string"
                  name={`socials.${index}.type`}
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value={'facebook'}>Facebook</MenuItem>
                  <MenuItem value={'twitter'}>Twitter</MenuItem>
                  <MenuItem value={'instagram'}>Instagram</MenuItem>
                  <MenuItem value={'google'}>Google</MenuItem>
                  <MenuItem value={'yelp'}>Yelp</MenuItem>
                  <MenuItem value={'tripadvisor'}>TripAdvisor</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Field>
              </Grid>
              <Grid item xs={6}>
                <Field
                  component={TextField}
                  name={`socials.${index}.link`}
                  type="string"
                  label="Link"
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  onClick={() => arrayHelpers.remove(index)} // skipcq: JS-0417
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}

          <Button
            onClick={() => arrayHelpers.push({ type: '', link: '' })} // skipcq: JS-0417
          >
            Add a Social link
          </Button>
        </>
      )} // skipcq: JS-0417
    />
  );
}

const SocialsForm = ({ values }) => {
  if (values === null) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2} p={2} alignItems="center">
        <Grid item xs={12}>
          <Field
            component={Switch}
            type="checkbox"
            label="Update Socials?"
            name={'updateSocials'}
          />
          Update Socials
        </Grid>
      </Grid>
      <SocialsGrid values={values} />
    </>
  );
};

export default SocialsForm;
