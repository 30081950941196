import React from 'react';
import PropTypes from 'prop-types';
// Formik
import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';
// mui
import Grid from '@mui/material/Grid';

function AmenityInput(amenityType, handleChange, index) {
  const handleAmnityChange = React.useCallback(
    (e) => {
      handleChange(e);
    },
    [handleChange],
  );

  return (
    <Grid item xs={12} md={6} key={index}>
      <Field
        component={CheckboxWithLabel}
        type="checkbox"
        Label={{
          label: amenityType[1].label,
        }}
        name={`amenities.${amenityType[0]}`}
        onChange={handleAmnityChange}
      />
    </Grid>
  );
}

AmenityInput.propTypes = {
  amenity: PropTypes.object.isRequired,
  key: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
};
export default AmenityInput;
