import { useState, useEffect } from 'react';
// firestore
// import { query, collection, where, getDocs } from 'firebase/firestore';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebase';

// custom hook to retrieve a brewery

function useCompanyLocationOverride(id) {
  const [override, setOverride] = useState(null);

  /* function to get a brewery from the DB */
  useEffect(() => {
    const getOverride = async () => {
      /* function to get a brewery from the DB */

      const item = {};
      const overrideDocRef = doc(db, 'breweryOverrides', id);
      const odr = await getDoc(overrideDocRef);
      const od = odr.data();
      for (const field in od) {
        if (Object.prototype.hasOwnProperty.call(od, field)) {
          const value =
            od[field] instanceof Timestamp ? od[field].toDate() : od[field];
          item[field] = value;
        }
      }

      setOverride(item);
    };

    getOverride();
  }, [id]);

  return override;
}

export default useCompanyLocationOverride;
