import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
// formik
import { Field } from 'formik';
import { Switch } from 'formik-mui';
// mui
import Grid from '@mui/material/Grid';
// components
import OobGrid from './OobGrid';

const OobForm = ({
  handleBlur,
  setFieldTouched,
  setFieldValue,
  values,
  handleChange,
}) => {
  if (values === null) {
    return null;
  }

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Field
            component={Switch}
            type="checkbox"
            label="Update out of business?"
            name={'updateOob'}
          />
          Update Out of business indicator?
        </Grid>
      </Grid>
      <OobGrid
        values={values}
        handleBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
      />
    </>
  );
};

OobForm.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default OobForm;
