import { useEffect } from 'react';

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const tout = setTimeout(() => {
      fn(...deps);
    }, waitTime);

    return () => {
      clearTimeout(tout);
    };
  }, [deps, waitTime, fn]);
}
