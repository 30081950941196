import { useState, useEffect } from 'react';
// firestore
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '../utils/firebase';

// custom hook to retrieve a brewery

function useBrewery(id) {
  const [brewery, setBrewery] = useState(null);

  /* function to get a brewery from the DB */
  useEffect(() => {
    const getBrewery = async () => {
      const item = {};
      const breweryDocRef = doc(db, 'breweries', id);
      const b = await getDoc(breweryDocRef);
      const bd = b.data();
      for (const field in bd) {
        const value =
          bd[field] instanceof Timestamp ? bd[field].toDate() : bd[field];
        item[field] = value;
      }

      setBrewery(item);
    };

    getBrewery();
  }, [id]);

  return brewery;
}

export default useBrewery;
