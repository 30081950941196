// JavaScript
// src/breweries/Breweries.jsx
import React, { useState, useEffect, useCallback } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
// utils
import { db } from '../../utils/firebase';
// material
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// Components
import IttAppBar from '../../components/app/IttAppBar';
import BreweryCard from './BreweryCard';
import PageLoadingIndicator from '../../components/widgets/PageLoadingIndicator';
import ButtonHeader from '../../components/widgets/ButtonHeader';
// data
import states from '../../shared/states/usStates.json';

function StateSelector({ usState, handleChange }) {
  return (
    <FormControl style={{ width: '300px', margin: '20px' }}>
      <InputLabel>State</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={usState}
        label="State"
        onChange={handleChange}
      >
        {states.map((state) => (
          <MenuItem value={state.code} key={state.code}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function Breweries() {
  const [breweries, setBreweries] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [usState, setUsState] = useState('NH');

  const pageTitle = 'Breweries';
  const navigate = useNavigate();

  /* function to get all breweries from firestore in realtime */
  useEffect(() => {
    const bq = query(
      collection(db, 'breweries'),
      orderBy('name', 'asc'),
      where('state', '==', usState),
    );
    onSnapshot(bq, (querySnapshot) => {
      setBreweries(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        })),
      );
      setLoading(false);
    });
  }, [usState]);

  const handleChange = useCallback((event) => {
    setUsState(event.target.value);
  }, []);

  if (isLoading) {
    return <PageLoadingIndicator title={pageTitle} />;
  }

  return (
    <div>
      <IttAppBar title={pageTitle} />
      <ButtonHeader>
        <Button variant="contained" onClick={() => navigate('/home')}>
          Home
        </Button>
        <StateSelector usState={usState} handleChange={handleChange} />
        <Button variant="contained">Add Brewery</Button>
      </ButtonHeader>
      <Container fixed>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: '100vh' }}
        >
          {breweries.map((brewery) => (
            <BreweryCard
              key={brewery.id}
              id={brewery.id}
              brewery={brewery.data}
            />
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Breweries;
