import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
// mui
import Grid from '@mui/material/Grid';
// Formik
import { Field } from 'formik';
import { Switch } from 'formik-mui';
// components
import { getAmenityTypes } from '../../../../components/amenities/AmenityTypes';
import AmenityInput from './AmenityInput';

function AmenitiesGrid({ amenityTypes, handleChange, updateAmenities }) {
  if (!updateAmenities) return null;

  return Array.from(amenityTypes.entries()).map((amenityType, index) => {
    return AmenityInput(amenityType, handleChange, index);
  });
}

function AmenitiesForm({ handleChange, values }) {
  const amenityTypes = getAmenityTypes();
  const { updateAmenities } = values;
  return (
    <Grid container spacing={2} p={2} alignItems="center">
      <Grid item xs={12}>
        <Field
          component={Switch}
          type="checkbox"
          label="Update Amenities?"
          name={'updateAmenities'}
          // onChange={(e) => handleChange(e)}
        />
        Update Amenities
      </Grid>
      <AmenitiesGrid
        amenityTypes={amenityTypes}
        handleChange={handleChange}
        updateAmenities={updateAmenities}
      />
    </Grid>
  );
}

AmenitiesForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default AmenitiesForm;
