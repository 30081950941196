import React, { useState, useRef, useCallback } from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
//firebase
import { db, storage } from '../../../../utils/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
// MUI
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/joy/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
// icons
import ArrowBack from '@mui/icons-material/ArrowBack';
// image crop
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// libs
import centerAspectCrop from './centerAspectCrop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
// hooks
import { useParams, useNavigate } from 'react-router-dom';
import useBrewery from '../../../../hooks/useBrewery';
//components
import IttAppBar from '../../../../components/app/IttAppBar';
import LogoInstructions from './LogoInstructions';
import PageLoadingIndicator from '../../../../components/widgets/PageLoadingIndicator';

function AlertBar({ message, successOpen, handleCloseSnackbar }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={successOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      severity="success"
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity="error"
        sx={{ width: '100%' }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

function LogoForm() {
  const [aspect] = useState(1 / 1);
  const [completedCrop, setCompletedCrop] = useState();
  const [crop, setCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [url, setUrl] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  // useRef
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  // router
  const { id } = useParams();
  const brewery = useBrewery(id);
  const navigate = useNavigate();

  const handleCloseSnackbar = useCallback(() => {
    setAlertOpen(false);
  }, [setAlertOpen]);

  const navigateBack = useCallback(() => {
    navigate(`/brewery/${id}`);
  }, [id, navigate]);

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop],
  );

  const onImageLoad = useCallback(
    (e) => {
      if (aspect) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
      }
    },
    [aspect],
  );

  const onSelectFile = useCallback((e) => {
    // only 1 file at a time
    if (e.target.files && e.target.files.length > 1) {
      setAlertMessage('Please select a single file only');
      setAlertOpen(true);
      e.target.value = '';
      return null;
    }

    if (e.target.files && e.target.files.length === 1) {
      if (e.target.files[0].size > 200000) {
        setAlertMessage('File is too large, maximum file size 200kb');
        setAlertOpen(true);
        e.target.value = '';
        return null;
      }
      // skipcq: JS-W1042
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() ?? ''),
      );
      reader.readAsDataURL(e.target.files[0]);
      return null;
    }
    return null;
  }, []);

  function getCroppedImage(fileName) {
    const canvas = previewCanvasRef.current;

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      }, 'image/png');
    });
  }

  /* function to update document in firestore */
  const updateBrewery = useCallback(
    async (newUrl) => {
      const breweryDocRef = doc(db, 'breweries', id);

      const values = { logoUrl: newUrl };

      try {
        await updateDoc(breweryDocRef, values);
      } catch (err) {
        Sentry.captureException(err);
      }
    },
    [id],
  );

  const onSaveLogo = useCallback(() => {
    // TODO configure security on storage bucket

    const storageRef = ref(storage, `/brewery-logos/${id}/logo.png`);

    getCroppedImage('logo.png').then((blob) => {
      try {
        setIsSaving(true);
        const uploadTask = uploadBytesResumable(storageRef, blob);
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            );
            // update progress
            setPercentUploaded(percent);
          },
          (err) => {
            Sentry.captureException(err);
            setIsSaving(false);
          },
          () => {
            // download url
            getDownloadURL(storageRef).then((newUrl) => {
              setUrl(newUrl);
              // save the brewery
              updateBrewery(newUrl);
              setIsSaving(false);
            });
          },
        );
      } catch (err) {
        Sentry.captureException(err);
        setIsSaving(false);
      }
    });
  }, [id, updateBrewery]);

  if (brewery === null) {
    return <PageLoadingIndicator title="Edit Brewery" />;
  }

  //
  //destructure brewery
  const { name } = brewery;
  let { logoUrl } = brewery;

  if (!logoUrl) {
    logoUrl =
      'https://firebasestorage.googleapis.com/v0/b/id-tap-that-backend.appspot.com/o/app_icon50.png?alt=media&token=2c784a42-a970-42e2-98ed-d3c8645ecd6f';
  }

  if (url === '') {
    setUrl(logoUrl);
  }

  return (
    <>
      <IttAppBar title="Edit Brewery" />
      <AlertBar
        message={alertMessage}
        successOpen={alertOpen}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      <Container fixed>
        <Button onClick={navigateBack}>
          <ArrowBack />
          Back to {name}
        </Button>
        <Grid container p={2} spacing={2}>
          <Grid item xs={6} md={4}>
            <img src={url} alt={'Logo'} width="200" />
          </Grid>
          <Grid xs={6} md={8}>
            <LogoInstructions name={name} />
          </Grid>
        </Grid>
        <Divider textAlign="left">Upload a new logo</Divider>
        <Grid container p={2} spacing={2}>
          <Grid item xs={6}>
            <input type="file" accept="image/*" onChange={onSelectFile} />
          </Grid>
          <Grid item xs={6}>
            {Boolean(completedCrop) && (
              <Button
                variant="contained"
                color="primary"
                disabled={isSaving}
                onClick={onSaveLogo}
                style={{ margin: '5px' }}
              >
                Upload
              </Button>
            )}
            {isSaving && (
              <Typography>
                Uploading image: {percentUploaded}% complete
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {Boolean(imgSrc) && (
              <>
                <Typography>Crop the image if required</Typography>
                <ReactCrop
                  crop={crop}
                  onChange={setCrop}
                  onComplete={setCompletedCrop}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    onLoad={onImageLoad}
                    role="presentation"
                  />
                </ReactCrop>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {Boolean(completedCrop) && (
              <>
                <Typography>Preview, click Upload when done</Typography>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

LogoForm.propTypes = {
  values: PropTypes.object,
};

export default LogoForm;
