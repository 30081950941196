import React from 'react';
import PropTypes from 'prop-types';
//
import Link from '@mui/material/Link';
//
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import SquareIcon from '@mui/icons-material/SquareRounded';

const validTypes = [
  'facebook',
  'twitter',
  'instagram',
  'google',
  'yelp',
  'tripadvisor',
  'other',
];

Social.propTypes = {
  social: PropTypes.shape({
    link: PropTypes.string,
    type: PropTypes.oneOf(validTypes),
  }),
};

function Social({ social }) {
  if (social === null) {
    return null;
  }

  const { link, type } = social;
  if (!link || !type || !validTypes.includes(type)) {
    return null;
  }

  return (
    <Link href={link} rel="noreferrer noopener" target="_blank">
      {type === 'facebook' && <FacebookIcon />}
      {type === 'twitter' && <TwitterIcon />}
      {type === 'instagram' && <InstagramIcon />}
      {type === 'google' && <GoogleIcon />}
      {type === 'yelp' && <SquareIcon />}
      {type === 'tripadvisor' && <SquareIcon />}
      {type === 'other' && <SquareIcon />}
    </Link>
  );
}

export default Social;
