import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
//mui
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
// icons
import ArrowRight from '@mui/icons-material/ArrowRight';

function LogoInstructions({ name }) {
  return (
    <>
      <Typography variant="h4">{name} - Logo</Typography>
      <Typography>
        This logo will be used to identify the Brewery in the mobile app and the
        web app.
      </Typography>
      <Typography>Guidelines for a good logo image</Typography>
      <ListItem dense>
        <ListItemIcon>
          <Icon fontSize="small">
            <ArrowRight />
          </Icon>
        </ListItemIcon>
        <ListItemText
          sx={{ my: 0 }}
          primary="Image should be sharp and clear"
        />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <Icon fontSize="small">
            <ArrowRight />
          </Icon>
        </ListItemIcon>
        <ListItemText
          sx={{ my: 0 }}
          primary="Background should be a solid color"
        />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <Icon fontSize="small">
            <ArrowRight />
          </Icon>
        </ListItemIcon>
        <ListItemText sx={{ my: 0 }} primary="Image should be square" />
      </ListItem>
      <ListItem dense>
        <ListItemIcon>
          <Icon fontSize="small">
            <ArrowRight />
          </Icon>
        </ListItemIcon>
        <ListItemText sx={{ my: 0 }} primary="File size maximum 200kb" />
      </ListItem>

      <Typography>
        To change the logo use the &aposChoose file&apos button to begin.
      </Typography>
    </>
  );
}

LogoInstructions.propTypes = {
  name: PropTypes.string,
};

export default LogoInstructions;
