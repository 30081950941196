import { useState, useEffect } from 'react';
// firestore
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../utils/firebase';

// custom hook to retrieve a brewery

function useBrewerySnapshot(id) {
  const [brewery, setBrewery] = useState(null);

  /* function to get a brewery from the DB */
  useEffect(() => {
    const getBrewery = () => {
      onSnapshot(doc(db, 'breweries', id), (document) => {
        const item = document.data();
        setBrewery(item);
      });
    };

    getBrewery();
  }, [id]);

  return brewery;
}

export default useBrewerySnapshot;
