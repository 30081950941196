import React from 'react';
// Material UI
import Container from '@mui/material/Container';
import Grid from '@mui/joy/Grid';

function ButtonHeader(props) {
  return (
    <Container fixed>
      <Grid container p={2} spacing={2}>
        <Grid
          container
          spacing={2}
          item
          xs={12}
          justifyContent={'space-between'}
          alignItems="center"
        >
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ButtonHeader;
