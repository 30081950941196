import React, { useEffect, useContext } from 'react';
// libs
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, logout } from '../../utils/firebase';
import { useNavigate } from 'react-router-dom';
// context
import { UserContext } from '../../context/UserContext';
// joy UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
//
import ModeSwitcher from './ModeSwitcher';

export default function IttAppBar({ title }) {
  const [loading] = useAuthState(auth);
  const navigate = useNavigate();

  const user = useContext(UserContext);
  const { isGlobalAdmin } = user;

  // menu handlers
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate('/home');
  }, [user, loading, navigate]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="neutral"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {user ? (
            <Button color="neutral" variant="solid" onClick={logout}>
              Logout
            </Button>
          ) : null}
          <ModeSwitcher />
        </Toolbar>
      </AppBar>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        aria-labelledby="basic-demo-button"
      >
        {isGlobalAdmin && (
          <MenuItem onClick={() => navigate('/breweries')}>Breweries</MenuItem>
        )}
        {isGlobalAdmin && (
          <MenuItem onClick={() => navigate('/users')}>Users</MenuItem>
        )}
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}
