import React from 'react';

// theme
import { CssVarsProvider } from '@mui/joy/styles';
import { theme } from './utils/theme';
// components
import { AppRoutes } from './routes/AppRoutes';

function App() {
  return (
    <CssVarsProvider theme={theme}>
      <div className="App">
        <AppRoutes />
      </div>
    </CssVarsProvider>
  );
}

export default App;
