import React from 'react';
import { createRoot } from 'react-dom/client';
// Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
//load the app
import './index.css';
import App from './App';
// roboto font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// ReactDOM.render(<App />, document.getElementById('root'));
const container = document.getElementById('App');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
