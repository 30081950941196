// JavaScript
// src/firebase.js
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  // connectFirestoreEmulator
} from 'firebase/firestore';
import {
  GoogleAuthProvider,
  getAuth,
  signOut,
  signInWithPopup,
} from 'firebase/auth';
import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import * as Sentry from '@sentry/browser';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();
const auth = getAuth();
const functions = getFunctions();
const storage = getStorage(app);
const currentURL = window.location.href;

//for debnugging on localhost
if (currentURL.includes('localhost')) {
  // connectFirestoreEmulator(db, 'localhost', 8080);
  // connectFunctionsEmulator(functions, 'localhost', 5001);
  window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

/**
 * ====================
 * CLOUD FUNCTIONS
 * ====================
 */
/**
 * userGrantGlobalAdmin
 * callable cloud function to set globalAmin flag on a user
 */
// const userGrantGlobalAdmin = httpsCallable(
//   functions,
//   'callableUserGrantGlobalAdmin',
// );
const listUsers = httpsCallable(functions, 'callableUserListUsers');

/**
 * ===================
 * FUNCTIONS
 * ===================
 */
/**
 * Google signin
 */
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    return res;
  } catch (err) {
    Sentry.captureException(err);
    return err;
  }
};

/**
 * Logout
 */
const logout = () => {
  signOut(auth);
};

/**
 * function that checks if the current user has the globalAdmin custom scope
 */
const isGlobalAdmin = async (user) => {
  if (!user) return null;

  const result = await user
    .getIdTokenResult(true) // 1
    .then((idTokenResult) => {
      return idTokenResult.claims.globalAdmin;
    });
  return result;
};

// register a listener for authentication state changes
// see https://johnwcassidy.medium.com/firebase-authentication-hooks-and-context-d0e47395f402
function onAuthStateChange(callback) {
  return auth.onAuthStateChanged((user) => {
    if (user) {
      const userName = user.displayName;
      isGlobalAdmin(user).then((result) =>
        // skipcq: JS-0255
        callback({
          isGlobalAdmin: result,
          isLoggedIn: true,
          userName,
        }),
      );
    } else {
      callback({ isGlobalAdmin: false, isLoggedIn: false }); // skipcq: JS-0255
    }
  });
}

export {
  auth,
  db,
  appCheck,
  // grantGlobalAdmin,
  isGlobalAdmin,
  listUsers,
  logout,
  signInWithGoogle,
  storage,
  onAuthStateChange,
  firebaseConfig,
};
