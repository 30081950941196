import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
//
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function FormDivider({ title }) {
  const lineWidth = 2;
  const lineColor = '#272930';
  return (
    <Divider
      textAlign="left"
      variant="middle"
      sx={{
        '&:before': { borderTopWidth: lineWidth, borderTopColor: lineColor },
        '&:after': { borderTopWidth: lineWidth, borderTopColor: lineColor },
      }}
    >
      <Typography variant="h6">{title}</Typography>
    </Divider>
  );
}

FormDivider.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormDivider;
