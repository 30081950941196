import React from 'react'; // skipcq: JS-0128
import PropTypes from 'prop-types';
// Formik
import { Field } from 'formik';
import { TextField, Select } from 'formik-mui';
import InputMask from 'react-input-mask';
// mui
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
// components
import OobForm from './oobForm/OobForm';
// data
import states from '../../../shared/states/usStates.json';

function AddressGrid({ handleBlur }) {
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Field
          component={TextField}
          name="name"
          type="string"
          label="Brewery Name"
          variant="standard"
          fullWidth
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          type="string"
          label="Address"
          name="address1"
          variant="standard"
          fullWidth
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          component={TextField}
          type="string"
          label="City"
          name="city"
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          component={Select}
          id="state"
          type="string"
          label="State"
          name="state"
          variant="standard"
          fullWidth
          sx={{ width: 205 }}
        >
          {states.map((state) => (
            <MenuItem value={state.code} key={state.code}>
              {state.name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={6} md={6}>
        <Field
          component={TextField}
          type="string"
          label="Zip"
          name="zip"
          variant="standard"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

function ContactGrid({ handleBlur, handleChange, values }) {
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12} md={6}>
        <InputMask
          mask="999 999 9999"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          maskplaceholder={''}
        >
          {(inputProps) => (
            <Field
              {...inputProps}
              component={TextField}
              type="string"
              label="Phone"
              name="phone"
              variant="standard"
              onChange={handleChange}
              fullWidth
            />
          )}
        </InputMask>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          component={TextField}
          type="string"
          label="Email"
          name="email"
          variant="standard"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Field
          component={TextField}
          type="string"
          label="Website"
          name="website"
          variant="standard"
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

function DetailsForm({
  handleChange,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  values,
}) {
  return (
    <>
      <AddressGrid handleBlur={handleBlur} />
      <ContactGrid
        handleBlur={handleBlur}
        handleChange={handleChange}
        values={values}
      />
      <OobForm
        handleBlur={handleBlur}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        values={values}
        handleChange={handleChange}
      />
    </>
  );
}

DetailsForm.propTypes = {
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
};

export default DetailsForm;
