import React from 'react';
import PropTypes from 'prop-types';
//
import { useNavigate, useParams } from 'react-router-dom';
// material-ui
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
// icons
import Edit from '@mui/icons-material/Edit';

function BreweryLogo({ logoUrl }) {
  const navigate = useNavigate();
  const { id } = useParams();

  const navigateToEdit = React.useCallback(
    () => navigate(`/brewery/logo/edit/${id}`),
    [navigate, id],
  );

  if (!logoUrl) {
    logoUrl =
      'https://firebasestorage.googleapis.com/v0/b/id-tap-that-backend.appspot.com/o/app_icon50.png?alt=media&token=2c784a42-a970-42e2-98ed-d3c8645ecd6f';
  }
  return (
    <Grid container padding={2} spacing={2}>
      <Grid item xs={12} alignItems="start" style={{ display: 'flex' }}>
        <img src={logoUrl} alt={'logo'} width="200" />
      </Grid>
      <Grid item xs={12}>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          aria-label="Edit brewery details"
          onClick={navigateToEdit}
        >
          <Edit />
          Change Logo
        </Button>
      </Grid>
    </Grid>
  );
}

BreweryLogo.propTypes = {
  logoUrl: PropTypes.string,
};

export default BreweryLogo;
