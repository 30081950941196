import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
// utils
import { auth, signInWithGoogle } from '../../utils/firebase';
// JoyUI
import Button from '@mui/joy/Button';
import Grid from '@mui/material/Grid';
// components
import IttAppBar from '../../components/app/IttAppBar';

function Login() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    // check here if is admin
    if (user) {
      navigate('/breweries');
    }
  }, [user, loading, navigate]);

  return (
    <div>
      <IttAppBar title="Login" />
      <div style={{ paddingTop: '10px' }}>
        <Grid
          container
          rowSpacing={2}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={12} justifyItems="center">
            <div>I'd Tap That Admin tool, for administrators only</div>
          </Grid>
          <Grid item xs={12} justifyItems="center">
            <span>Use your Google Accont to Login</span>
          </Grid>
          <Grid item xs={12}>
            <Button color="primary" variant="solid" onClick={signInWithGoogle}>
              Login with Google
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Login;
