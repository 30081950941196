import React from 'react'; // skipcq: JS-0128
// libs
import { useNavigate } from 'react-router-dom';
// Material UI
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/joy/Typography';
// config
import { XS_CARD_WIDTH, MD_CARD_WIDTH } from '../../utils/constants';
// components
import Socials from '../../components/socials/Socials';
import Amenities from '../../components/amenities/Amenities';
// Icons
import PublicIcon from '@mui/icons-material/Public';
import sanitizedUrl from '../../utils/sanitizeURL';

function BreweryCard({ brewery, id }) {
  const navigate = useNavigate();

  // if there is not a brewery get out of here
  if (brewery === null) {
    return null;
  }

  const {
    name,
    address1,
    city,
    state,
    zip,
    website,
    phone,
    isOob,
    oobDate,
    socials = [],
    amenities = {},
  } = brewery;

  let { logoUrl } = brewery;

  if (!logoUrl) {
    logoUrl =
      'https://firebasestorage.googleapis.com/v0/b/id-tap-that-backend.appspot.com/o/app_icon50.png?alt=media&token=2c784a42-a970-42e2-98ed-d3c8645ecd6f';
  }

  const url = sanitizedUrl(website);
  return (
    <Card
      row
      variant="outlined"
      sx={{
        minWidth: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
        },
        width: {
          xs: XS_CARD_WIDTH,
          md: MD_CARD_WIDTH,
        },
        margin: '4px',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isOob && (
            <Typography textColor="red">Out of business {oobDate}</Typography>
          )}
        </Grid>
        <Grid item xs={8} md={4}>
          <Typography level="h5">{name}</Typography>
          <Typography level="body2">{address1}</Typography>
          <Typography level="body2">
            {city} {state} {zip}
          </Typography>
          <Typography level="body2">{phone}</Typography>
        </Grid>
        <Grid item xs={4} md={8}>
          <img src={logoUrl} alt={name} width="100" />
        </Grid>
        <Grid item xs={1} md={1}>
          {website && (
            <Link href={url} target="_blank" rel="noopener">
              <PublicIcon />
            </Link>
          )}
        </Grid>
        <Grid item xs={11} md={5}>
          <Socials socials={socials} />
        </Grid>

        <Grid item xs={12}>
          <Amenities
            amenities={amenities}
            settings={{ showLabels: false, vertical: false }}
          />
        </Grid>
        {/* Action button */}
        <Grid item xs={12} md={12} container justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={() => navigate(`/brewery/${id}`)}
          >
            View
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}

export default BreweryCard;
