// JavaScript
// src/breweries/Breweries
import React from 'react';
import { useState, useEffect } from 'react';
import { listUsers } from '../../utils/firebase';
//
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/joy/CircularProgress';
// Components
import IttAppBar from '../../components/app/IttAppBar';
import User from './User';

function Users() {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(true);

  /* function to get all tasks from firestore in realtime */
  useEffect(() => {
    let isSubscribed = true;
    const getUsers = async () => {
      const result = await listUsers();
      const { data } = result;
      const { users: usersList } = data;
      // retrn users;
      if (isSubscribed) {
        setUsers(usersList);
        setLoading(false);
      }
    };
    getUsers().catch(console.error);

    return () => (isSubscribed = false);
  }, [setUsers]);

  if (isLoading) {
    return (
      <>
        <IttAppBar title="Breweries" />
        <CircularProgress
          color="warning"
          variant="solid"
          style={{ top: '50px', left: '50%' }}
        />
        ;
      </>
    );
  }

  return (
    <div>
      <IttAppBar title="Users" />

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh' }}
      >
        {users.map((user) => (
          <User key={user.uid} user={user} />
        ))}
      </Grid>
    </div>
  );
}

export default Users;
