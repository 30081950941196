import React from 'react';
import PropTypes from 'prop-types';
// Material UI
import Typography from '@mui/material/Typography';
// Icons
import { IconContext } from 'react-icons';
import Dangerous from '@mui/icons-material/Dangerous';
//colors
import { brown, grey } from '@mui/material/colors';

Amenity.propType = {
  amenityValue: PropTypes.bool,
};

function Amenity({ amenityValue, amenityType, settings }) {
  const wrapperStyle = {
    display: settings.vertical ? 'flex' : 'inline-flex',
    paddingRight: '4px',
    paddingBottom: '8px',
  };
  const iconColor = brown[500];
  const negativeIconColor = grey[400];

  const amenityTypeObject = amenityType[1];
  return (
    <>
      {amenityValue === true && (
        <IconContext.Provider
          value={{ color: brown[500], size: '24px', verticalAlign: 'middle' }}
        >
          <Typography
            level="body2"
            justifyContent="flex-start"
            component="span"
            alignContent="center"
            style={wrapperStyle}
          >
            {amenityTypeObject.icon}
            {settings.showLabels !== false && (
              <span style={{ paddingLeft: '5px', color: iconColor }}>
                {amenityTypeObject.label}
              </span>
            )}
          </Typography>
        </IconContext.Provider>
      )}
      {amenityValue === false && amenityTypeObject.displayAnti && (
        <span style={{ position: 'relative' }}>
          <IconContext.Provider
            value={{ color: negativeIconColor, size: '24px' }}
          >
            <Typography
              component="span"
              level="body2"
              justifyContent="flex-start"
              alignContent="center"
              style={wrapperStyle}
              color={negativeIconColor}
            >
              {amenityTypeObject.icon}
              <span
                style={{
                  position: 'absolute',
                  left: settings.vertical ? '10px' : '14px',
                  top: settings.vertical ? '-10px' : '-14px',
                  align: 'left',
                }}
              >
                <Dangerous fontSize="small" color={negativeIconColor} />
              </span>
              {settings.showLabels !== false && (
                <span style={{ paddingLeft: '5px', color: negativeIconColor }}>
                  {amenityTypeObject.antiLabel}
                </span>
              )}
            </Typography>
          </IconContext.Provider>
        </span>
      )}
    </>
  );
}

export default Amenity;
