import * as React from 'react';
//
import { useColorScheme } from '@mui/joy/styles';
//
import IconButton from '@mui/joy/IconButton';
//
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

export default function ModeSwitcher() {
  // const ModeSwitcher = () => {
  const { mode, setMode } = useColorScheme();

  return (
    <IconButton
      sx={{ ml: 1 }}
      onClick={() => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
      }}
      color="inherit"
    >
      {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  );
}
