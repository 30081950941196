import React from 'react';
import PropTypes from 'prop-types';
// MaterialUI
import CircularProgress from '@mui/joy/CircularProgress';
//
import IttAppBar from '../app/IttAppBar';

PageLoadingIndicator.propType = {
  title: PropTypes.string,
};

function PageLoadingIndicator({ title }) {
  return (
    <>
      <IttAppBar title={title} />
      <CircularProgress
        color="warning"
        variant="solid"
        style={{ top: '50px', left: '50%' }}
      />
      ;
    </>
  );
}

export default PageLoadingIndicator;
