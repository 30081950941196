const cleanOverride = async (values, brewery) => {
  let override = await structuredClone(values);

  // delete oob indicator if it's not being changed
  if (brewery.isOob === false && override.isOob === false) {
    delete override.oobDate;
    delete override.isOob;
  }

  // if update Oob flag is false, delete the oobDate and isOob flag
  if (override.updateOob === false) {
    delete override.isOob;
    delete override.oobDate;
    delete override.updateOob;
  }
  // if update Amenites flas is false, delete the amenities object
  if (override.updateAmenities === false) {
    delete override.amenities;
    delete override.updateAmenities;
  }

  if (override.updateSocials === false) {
    delete override.socials;
    delete override.updateSocials;
  }

  Object.entries(override).forEach(([key, value]) => {
    // delete empty or null values
    if (value === '' || value === null || value === undefined) {
      delete override[key];
    }

    // delete empty arrays
    if (
      value &&
      typeof value === 'object' &&
      value.length &&
      value.length === 0
    ) {
      delete override[key];
    }

    // delete empty objects
    if (
      value &&
      typeof value === 'object' &&
      Object.entries(value).length === 0
    ) {
      delete override[key];
    }
  });

  return override;
};

export default cleanOverride;
